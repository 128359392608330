import React from "react";
import { Link } from "react-router-dom";
import "../style/odds.css";

function BlockedClients(props) {
  return (
    <div>
      <div className="header-container">
        <h1>Blocked Clients</h1>
        <div>
          {" "}
          <Link to={"/"} className="tag-link">
            Dashboard
          </Link>{" "}
          / <b>Blocked Client</b>
        </div>
      </div>
      <div className="box-container">
        <div className="box table" style={{ maxWidth: "100%" }}>
          <div
            className="box-header"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <span>Blocked Users</span>
          </div>
          <div className="box-body">
            <table style={{ width: "100%" }} className="normal client-table">
              <thead>
                <tr>
                  <th>User Name</th>
                  <th>Share</th>
                  <th>Status</th>
                  <th colSpan="2">Actions</th>
                </tr>
              </thead>
              <tbody></tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BlockedClients;
