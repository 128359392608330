import React, { useState, useEffect, useContext } from "react";
import Spinner from "./Spinner";
import axios from "axios";
import { AuthContext } from "../context/AuthContext";
import { Link, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import api_url from "../constants";
import { ReqCallContext } from "../context/ReqCallContext";

function RecieveCash() {
  const { userId } = useParams();
  const [authContext, setAuthContext] = useContext(AuthContext);
  const [reqCall, setReqCall] = useContext(ReqCallContext);
  const config = {
    headers: { Authorization: `Bearer ${authContext.token}` },
  };
  const [loading, setLoading] = useState(true);
  const [exposure, setExposure] = useState("");
  const [exposureLoading, setExposureLoading] = useState(true);
  const [cuLoading, setCuLoading] = useState(false);
  const [ledger, setLedger] = useState("");
  const [note, setNote] = useState("");
  const [userInfo, setUserInfo] = useState({});
  const fetchUser = async () => {
    const response = await axios.get(
      `${api_url}/auth/getUserByUsername/${userId}`,
      config
    );
    setUserInfo(response.data);
    setLoading(false);
  };
  const fetchExposure = async () => {
    const response = await axios.get(
      `${api_url}/ledger/cashExpo/${userId}`,
      config
    );
    const { cash, exposure } = response.data;
    setExposure((Math.round((exposure + cash) * 100) / 100) * -1);
    setExposureLoading(false);
  };

  useEffect(() => {
    setReqCall(reqCall + 1);
    fetchUser();
    fetchExposure();
  }, [userId]);
  const level = userInfo.level;
  const levelname =
    level === 2
      ? "Super Company"
      : level === 3
      ? "Super Stockist"
      : level === 4
      ? "Stockist"
      : "Agent";

  const handleSubmit = async (e) => {
    e.preventDefault();
    setCuLoading(true);
    const response = await axios.post(
      `${api_url}/ledger/receiveCash`,
      { ledger, note, username: userId },
      config
    );

    await fetchExposure();
    setLedger("");
    setNote("");
    if (response.data.status) {
      toast.success(response.data.msg);
    } else {
      toast.error(response.data.msg);
    }
    setCuLoading(false);
  };

  if (loading || exposureLoading) {
    return <Spinner color="#000" style={{ marginTop: 10 }} />;
  }
  return (
    <div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <div className="header-container">
        <h1>Receive Cash</h1>
        <Link to={"/"} className="tag-link">
          Dashboard
        </Link>{" "}
        / {levelname} / <span className="uppercase">{userInfo.username}</span>(
        {userInfo.name.trim()}) / <b>Receive cash</b>
      </div>
      <div className="box-container">
        <div className="box" style={{ maxWidth: "100%" }}>
          <div
            className="box-header"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <span>Receive Cash From Users</span>
          </div>
          <form
            className="box-body"
            style={{ paddingTop: "10px", backgroundColor: "#fff" }}
            method="post"
            onSubmit={handleSubmit}
          >
            <div className="flex-container">
              <div className="flex-elem">Agent Name: </div>
              <div className="input-cnt input-id">
                <span className="uppercase">{userId}</span>
              </div>
            </div>
            <div className="flex-container">
              <div className="flex-elem">Rs. Exposure: </div>
              <div className="input-cnt">
                <span>{exposure}</span>
              </div>
            </div>
            <div className="flex-container">
              <div className="flex-elem">Update Ledger: </div>
              <div className="input-cnt ">
                <input
                  type="number"
                  autoComplete="off"
                  name="update"
                  value={ledger}
                  onChange={(e) => setLedger(e.target.value)}
                />
              </div>
            </div>
            <div className="flex-container">
              <div className="flex-elem">Note: </div>
              <div className="input-cnt ">
                <div>
                  <textarea
                    value={note}
                    onChange={(e) => setNote(e.target.value)}
                  />
                </div>
              </div>
            </div>

            <div className="flex-container">
              <div className="flex-elem"></div>
              <div className="input-cnt">
                <button name="newUser" type="submit">
                  {cuLoading ? <Spinner color="#fff" /> : "Save Changes"}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default RecieveCash;
