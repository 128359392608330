import React, { useState, useEffect, useContext } from "react";
import Spinner from "./Spinner";
import axios from "axios";
import { AuthContext } from "../context/AuthContext";
import { Link } from "react-router-dom";
import "../style/odds.css";
import api_url from "../constants";
import { format } from "date-fns";
import { ReqCallContext } from "../context/ReqCallContext";

function ColorGame(props) {
  const [authContext, setAuthContext] = useContext(AuthContext);
  const [reqCall, setReqCall] = useContext(ReqCallContext);
  const [pnl, setPnl] = useState(0);
  const config = {
    headers: { Authorization: `Bearer ${authContext.token}` },
  };
  function formatDate(dateStr) {
    const year = parseInt(dateStr.substr(0, 4), 10);
    const month = parseInt(dateStr.substr(4, 2), 10) - 1; // month is zero-based in Date
    const day = parseInt(dateStr.substr(6, 2), 10);
    const minuteOfDay = parseInt(dateStr.substr(8), 10) * 3;

    const date = new Date(year, month, day);
    date.setMinutes(minuteOfDay);

    const formattedDate = `${date.getDate()} ${getMonthName(
      date.getMonth()
    )} ${formatTime(date)}`;
    return formattedDate;
  }

  function getMonthName(month) {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    return months[month];
  }

  function formatTime(date) {
    let hours = date.getHours();
    let minutes = date.getMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12; // 12-hour clock
    minutes = minutes < 10 ? "0" + minutes : minutes;
    return `${hours}:${minutes} ${ampm}`;
  }
  const [betList, setBetList] = useState([]);
  const [loading, setLoading] = useState(true);
  const getBets = async () => {
    const response = await axios.get(
      `${api_url}/colorGame/getPlayerBets`,
      config
    );
    setBetList(response.data.result);
    setLoading(false);
  };
  const getAllTimePnl = async () => {
    const response = await axios.get(
      `${api_url}/colorGame/getAllTimePnl`,
      config
    );
    setPnl(response.data.result);
  };
  useEffect(() => {
    setReqCall(reqCall + 1);
    getBets();
    getAllTimePnl();
  }, []);

  return (
    <div>
      <div className="box-container">
        <div className="box">
          <div className="box-header">All time earning</div>
          <div className="box-body">
            <h1>{pnl}</h1>
          </div>
        </div>
      </div>
      <div className="header-container">
        <h1>Color game result</h1>
        <div>
          {" "}
          <Link to={"/"} className="tag-link">
            Dashboard
          </Link>{" "}
          / Color game result
        </div>
      </div>

      <div className="box-container">
        <div className="box table" style={{ maxWidth: "100%" }}>
          <div
            className="box-header"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <span>All Matches</span>
          </div>
          <div className="box-body">
            <table className="normal" style={{ width: "100%" }}>
              <thead>
                <tr>
                  <th>Period</th>
                  <th>Time</th>
                  <th>Declared</th>
                  <th>Result Color</th>
                  <th>Profit/Loss</th>
                </tr>
              </thead>
              <tbody>
                {!betList.length && loading ? (
                  <tr colSpan="10">
                    <td colSpan={6}>
                      <Spinner color="#888" />
                    </td>
                  </tr>
                ) : (
                  betList
                    .sort((a, b) => b._id - a._id)
                    .map((x) => {
                      const classColor =
                        x.pnl >= 0 ? "text-info" : "text-danger";
                      const firstColor =
                        x.resultColor === 1
                          ? "grn"
                          : x.resultColor === 2
                          ? "red"
                          : "vlt";
                      return (
                        <tr key={x._id}>
                          <td>{x._id}</td>
                          <td>{formatDate(x._id)}</td>
                          <td>Yes</td>
                          <td className="color-td flex-td">
                            {x.settled ? (
                              <div
                                className={`color-crcl ${firstColor}-crcl`}
                              ></div>
                            ) : null}
                          </td>
                          <td className={`font-bold ${classColor}`}>{x.pnl}</td>
                        </tr>
                      );
                    })
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ColorGame;
