import React, { useContext, useEffect, useState } from "react";
import Spinner from "./Spinner";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { AuthContext } from "../context/AuthContext";
import api_url from "../constants";
import { Link, useParams } from "react-router-dom";
import { ReqCallContext, ReqCallProvider } from "../context/ReqCallContext";

function ChangePassword() {
  const { username } = useParams();
  const [userContext, setUserContext] = useContext(AuthContext);
  const [reqCall, setReqCall] = useContext(ReqCallContext);
  const [cuLoading, setCuLoading] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [err, setError] = useState("");
  useEffect(() => {
    setReqCall(reqCall + 1);
  }, []);
  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      setError("");
      setCuLoading(true);
      if (!newPassword || !confirmPassword) {
        setError("Fields cannot be empty.");
        setCuLoading(false);
        return;
      }
      if (newPassword !== confirmPassword) {
        setError("New passwords do not match");
        setCuLoading(false);
        return;
      }
      const response = await axios.post(
        api_url + "/auth/editPassword",
        {
          newPassword,
          username: username.toLowerCase(),
        },
        {
          headers: {
            Authorization: `Bearer ${userContext.token}`,
          },
        }
      );

      if (response.data.status) {
        setNewPassword("");
        setConfirmPassword("");
        toast.success("Password updated successfully");
      } else {
        setError(response.data.msg);
      }
    } catch (error) {
      console.error(error);
      setError(
        error.response.data.msg ? error.response.data.msg : error.message
      );
    }
    setNewPassword("");
    setConfirmPassword("");
    setCuLoading(false);
  };
  return (
    <div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <div className="header-container">
        <h1>Change Password</h1>
        <div>
          {" "}
          <Link to={"/"} className="tag-link">
            Dashboard
          </Link>{" "}
          /{" "}
          <Link to={"/commisionAndLimits"} className="tag-link">
            Agents
          </Link>{" "}
          / <b>{username.toUpperCase()}</b>
        </div>
      </div>
      <div className="box-container">
        <div className="box" style={{ maxWidth: "100%" }}>
          <div
            className="box-header"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <span>All Users</span>
          </div>
          {err ? <div className="error-message">{err}</div> : ""}

          <form
            className="box-body"
            style={{ paddingTop: "10px" }}
            method="post"
            onSubmit={handleSubmit}
          >
            <div className="flex-container">
              <div className="flex-elem">New Password</div>
              <div className="input-cnt ">
                <input
                  placeholder="New Password"
                  type="password"
                  autoComplete="new-password"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                />
              </div>
            </div>
            <div className="flex-container">
              <div className="flex-elem">Confirm Password</div>
              <div className="input-cnt">
                <input
                  autoComplete="off"
                  type="password"
                  value={confirmPassword}
                  placeholder="Confirm Password"
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
              </div>
            </div>
            <div className="flex-container">
              <div className="flex-elem"></div>
              <div className="input-cnt">
                <button name="newUser" type={cuLoading ? "button" : "submit"}>
                  {cuLoading ? <Spinner color="#fff" /> : "Change Password"}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default ChangePassword;
