import React, { useEffect, useState, useContext } from "react";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import Spinner from "./Spinner";
import { AuthContext } from "../context/AuthContext";
import api_url from "../constants";
import { ReqCallContext, ReqCallProvider } from "../context/ReqCallContext";

function DirectClients(props) {
  const [authContext, setAuthContext] = useContext(AuthContext);
  const [userInfo, setUserInfo] = useState({});
  const [reqCall, setReqCall] = useContext(ReqCallContext);
  const { userId } = useParams();

  const config = {
    "Content-Type": "application/json",
    headers: { Authorization: `Bearer ${authContext.token}` },
  };
  const [dataLoading, setDataLoading] = useState(true);
  const [userList, getUserList] = useState([]);

  const fetchList = async () => {
    const getUserInfo = await axios.get(
      `${api_url}/auth/getUserByUsername/${userId}`,
      config
    );
    setUserInfo(getUserInfo.data);
    const response = await axios.get(
      `${api_url}/auth/getDirectClients/${userId}`,
      config
    );
    getUserList(response.data);
    setDataLoading(false);
  };

  useEffect(() => {
    setReqCall(reqCall + 1);
    fetchList();
  }, []);
  return (
    <div>
      <div className="header-container">
        <h1>Clients</h1>
        <div>
          {" "}
          <Link to={"/"} className="tag-link">
            Dashboard
          </Link>{" "}
          / Clients /{" "}
          <Link to={"/userDashboard/" + userInfo.username}>
            {userInfo.username}
          </Link>
          / Direct Clients
        </div>
      </div>
      <div className="box-container">
        <div className="box table" style={{ maxWidth: "100%" }}>
          <div
            className="box-header"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <span>All Users</span>
          </div>
          <div className="box-body">
            <table style={{ width: "100%" }}>
              <thead>
                <tr>
                  <th>Username</th>
                  <th>Name</th>
                  <th>Agent Under</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {dataLoading ? (
                  <tr colSpan="10">
                    <td colSpan={6}>
                      <Spinner color="#888" />
                    </td>
                  </tr>
                ) : (
                  userList.map((data) => (
                    <tr key={data._id}>
                      <td className="uppercase">
                        <Link to={`/userDashboard/${data.username}`}>
                          {data.username} ({data.name.trim()})
                        </Link>
                      </td>
                      <td>{data.name ? data.name : ""}</td>
                      <td>{data.companyId ? data.companyId : ""}</td>

                      <td>
                        <Link className="edit-btn">Edit</Link>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DirectClients;
