function calculatePosition(matchData, resp) {
  let matchBetData = JSON.parse(JSON.stringify(matchData));
  let data = JSON.parse(JSON.stringify(resp));
  for (var i = 0; i < data.length; i++) {
    const teamOdds = data[i];
    for (var j = 0; j < matchBetData.length; j++) {
      const pos = matchBetData[j];
      const { isBack, stake, odds, adminShare } = pos;

      if (teamOdds.nat === pos.selectionName) {
        if (!data[i].position) {
          data[i].position = 0;
        }
        if (isBack) {
          const val = parseFloat((odds * stake) / 100);
          data[i].position -= (val * adminShare) / 100;
        } else {
          const val = parseFloat((odds * stake) / 100);
          data[i].position += (val * adminShare) / 100;
        }
      } else {
        if (!data[i].position) {
          data[i].position = 0;
        }
        if (isBack) {
          const val = parseFloat(stake);
          data[i].position += (val * adminShare) / 100;
        } else {
          const val = parseFloat(stake);
          data[i].position -= (val * adminShare) / 100;
        }
      }
    }
  }
  return data;
}
export default calculatePosition;
