import React, { useEffect, useState, useContext } from "react";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import { AuthContext } from "../context/AuthContext";
import Spinner from "./Spinner";
import { useLayoutEffect } from "react";
import "../style/odds.css";
import { ToastContainer } from "react-toastify";
import CloseIcon from "@mui/icons-material/Close";
import "react-toastify/dist/ReactToastify.css";
import "../style/livereport.css";
import socketIOClient from "socket.io-client";
import api_url from "../constants";
import calculatePosition from "../helper/calculatePosition";
import { ReqCallContext } from "../context/ReqCallContext";
const ENDPOINT = "https://api2.fly247.in";

function PlayerLiveReport() {
  const [authContext, setAuthContext] = useContext(AuthContext);
  const [reqCall, setReqCall] = useContext(ReqCallContext);
  const config = {
    headers: { Authorization: `Bearer ${authContext.token}` },
  };
  const { matchId, playerId } = useParams();
  const [playerDetails, setPlayerDetails] = useState({});
  const [betData, setBetData] = useState([]);
  const [matchOdds, setMatchOdds] = useState([]);
  const [posData, setPosData] = useState({});
  const [loading, setLoading] = useState(true);
  const [matchScore, setMatchScore] = useState({});
  const [matchList, setMatchList] = useState([]);
  const [sessionOdds, setSessionOdds] = useState([]);
  const [t1, setT1] = useState([]);
  const [betLoading, setBetLoading] = useState(false);
  const [dataLoading, setDataLoading] = useState(false);
  const [openSTable, setOpenSTable] = useState(false);
  const [betDataLoading, setBetDataLoading] = useState(false);
  const [declaredSession, setDeclaredSession] = useState([]);
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "June",
    "July",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const totalStatus = declaredSession.reduce(
    (sum, session) => sum + session.sum,
    0
  );

  const type = [
    { s: "single", name: "1" },
    { s: "no run", name: "0" },
    { s: "four", name: "4" },
    { s: "six", name: "6" },
    { s: "double", name: "2" },
    { s: "over change", name: "Over" },
    { s: "wide and wicket", name: "wide & wicket" },
  ];
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [matchId]);
  useEffect(() => {
    const socket = socketIOClient(ENDPOINT);
    socket.emit("getMatchScore", matchId);
    socket.emit("getSinglePlayerBets", {
      matchId,
      player: playerId,
      token: authContext.token,
    });
    const interval = setInterval(() => {
      socket.emit("getMatchScore", matchId);
    }, 1000);
    const interval2 = setInterval(() => {
      socket.emit("getSinglePlayerBets", {
        matchId,
        player: playerId,
        token: authContext.token,
      });
    }, 10000);
    getMatchScore(socket);
    getPosition(socket);
    return () => {
      socket.disconnect();
      clearInterval(interval);
      clearInterval(interval2);
    };
  }, [matchId]);
  function getMatchScore(socket) {
    socket.on("matchScore", (data) => {
      if (data && data.status !== "Not found") {
        setMatchScore(data);
      }
    });

    socket.on("error", (message) => {
      setMatchScore(null);
    });
  }
  const fetchPlayer = async () => {
    const response = await axios.get(
      `${api_url}/auth/getUserByUsername/${playerId}`,
      config
    );

    setPlayerDetails(response.data);
    setLoading(false);
  };
  const fetchDeclaredSession = async () => {
    const response = await axios.get(
      `${api_url}/bet/getPlayerDeclaredSession/${matchId}/${playerId}`,
      config
    );
    setDeclaredSession(response.data);
  };
  async function getMatchData() {
    try {
      const response = await axios.get(`${api_url}/api/getMatchList`, config);
      const arr = response.data;
      arr.sort((a, b) => new Date(a.time) - new Date(b.time));
      setMatchList(arr);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  }
  useEffect(() => {
    setReqCall(reqCall + 1);
    fetchDeclaredSession();
    setLoading(true);
    setMatchOdds([]);
    setSessionOdds([]);
  }, [matchId]);
  useEffect(() => {
    fetchPlayer();
  }, [playerId]);
  useEffect(() => {
    getMatchData();
    return () => {
      setLoading(true);
      setBetLoading(true);
      setDataLoading(true);
      setMatchList([]);
      setMatchScore({});
    };
  }, []);

  String.prototype.allReplace = function (obj) {
    var retStr = this;
    for (var x in obj) {
      retStr = retStr.replace(new RegExp(x, "g"), obj[x]);
    }
    return retStr;
  };
  function formatAMPM(date) {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;
    var strTime = hours + ":" + minutes + " " + ampm;
    return strTime;
  }
  const convertToTimeStamp = (t) => {
    const timestamp = Date.parse(t) / 1000; // divide by 1000 to convert milliseconds to seconds
    return timestamp;
  };
  const getPosition = async (socket) => {
    socket.on("singlePlayerBets", async (resData) => {
      const data = resData.odds.data;
      const position = resData.betData.filter((x) => x.name === "matchbet");
      if (data) {
        if (data.BMmarket && data.BMmarket.bm1) {
          const res = calculatePosition(position, data.BMmarket.bm1);
          setSessionOdds(res);
        }
        if (data.Fancymarket) {
          const position = resData.betData.filter(
            (x) => x.name === "sessionbet"
          );
          setBetData(position);
          setMatchOdds(data.Fancymarket);
        }
      }
    });
    socket.on("error", (message) => {
      console.log(message);
      setMatchOdds([]);
      setSessionOdds([]);
    });
  };
  const isObjectEmpty = (objectName) => {
    return Object.keys(objectName).length === 0;
  };

  if (dataLoading || loading || betDataLoading) {
    return (
      <div className="no-padding">
        <Spinner color="#555" />
      </div>
    );
  }

  return (
    <div className="b-table live-report">
      {posData.nat ? (
        <div className="pos-modal">
          <div className="modal-body">
            <div className="modal-head">
              <div>
                <span className="flex1">{posData.nat}</span>
                <span>
                  <div
                    onClick={() => setPosData({})}
                    style={{ cursor: "pointer" }}
                  >
                    <CloseIcon style={{ fontSize: 30 }} />
                  </div>
                </span>
              </div>
            </div>
            <div className="modal-10">
              <table>
                <thead>
                  <tr>
                    <th>RUNS</th>
                    <th>POSITION</th>
                  </tr>
                </thead>
                <tbody>
                  {posData.posArr && posData.posArr.length
                    ? posData.posArr
                        .filter((x) => x.n >= 0)
                        .map((x) => (
                          <tr key={x.n}>
                            <td className="dark-td">{x.n}</td>
                            <td className={x.sum >= 0 ? "success" : "warning"}>
                              {Math.round(x.sum * 100) / 100}
                            </td>
                          </tr>
                        ))
                    : null}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      ) : null}
      <div className="header-container">
        <h1>
          LIVE PLAYER REPORT - {playerDetails ? playerDetails.username : ""}(
          {playerDetails ? playerDetails.name : ""})
        </h1>
        <div>
          <Link to={"/"} className="tag-link">
            Dashboard
          </Link>{" "}
          / <b>Player Live Report</b>
          <div className="no-padding mt-15">
            <ToastContainer
              position="top-right"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              theme="light"
            />
            <div className="scoreboard">
              <div className="score" style={{ fontSize: 13 }}>
                <div>
                  {matchScore !== undefined && !isObjectEmpty(matchScore) ? (
                    <div>
                      <span>{matchScore.team1 ? matchScore.team1 : null}</span>{" "}
                      -{" "}
                      {matchScore.score1
                        ? `${matchScore.score1}${matchScore.team1Over}`
                        : null}
                    </div>
                  ) : (
                    <div>-</div>
                  )}
                  {matchScore !== undefined && !isObjectEmpty(matchScore) ? (
                    <div>
                      <span>{matchScore.team2 ? matchScore.team2 : null}</span>{" "}
                      -{" "}
                      {matchScore.score2
                        ? `${matchScore.score2}${matchScore.team2Over}`
                        : null}
                    </div>
                  ) : (
                    <div>-</div>
                  )}
                  {matchScore !== undefined && !isObjectEmpty(matchScore) ? (
                    <div>
                      <span className="sm-msg">
                        {matchScore.message ? matchScore.message : null}
                      </span>
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="score-cnt">
                <div style={{ textTransform: "capitalize" }}>
                  {matchScore !== undefined &&
                  !isObjectEmpty(matchScore) &&
                  matchScore.status !== undefined
                    ? type.filter(
                        (x) => x.s === matchScore.status.toLowerCase()
                      ).length > 0
                      ? type.filter(
                          (x) => x.s === matchScore.status.toLowerCase()
                        )[0].name
                      : matchScore.status
                    : "-"}
                </div>
              </div>
            </div>
            <div className="pr-box">
              <div className="prbox-header">
                <span>Market : Min : 100.0 | Max : 200000.0</span>
                <span>({playerDetails ? playerDetails.name : ""})</span>
              </div>
              <div className="prbox-body tunnel">
                <table className="normal">
                  <thead>
                    <tr>
                      <th>RUNNER</th>
                      <th>LAGAI</th>
                      <th>KHAI</th>
                      <th>POSITION</th>
                    </tr>
                  </thead>
                  <tbody>
                    {sessionOdds.length
                      ? sessionOdds
                          .sort(function (a, b) {
                            return parseInt(a.sr) - parseInt(b.sr);
                          })
                          .map((x) => {
                            const lay = x.l1;
                            const back = x.b1;
                            return (
                              <tr key={x.sid}>
                                <td>{x.nat}</td>
                                <td className="btn-td">{back}</td>
                                <td className="btn-td">{lay}</td>
                                <td>
                                  {x.position
                                    ? Math.round(x.position * 100) / 100
                                    : 0.0}
                                </td>
                              </tr>
                            );
                          })
                      : null}
                  </tbody>
                </table>
              </div>
            </div>

            {sessionOdds.length ? null : t1.length ? (
              <div className="pr-box">
                <div className="prbox-header">TOSS WINNER</div>
                <div className="prbox-body tunnel g-toss">
                  <table className="normal vertical">
                    <thead>
                      <tr>
                        <th>RUNNER</th>
                        <th>LAGAI</th>
                        <th>KHAI</th>
                        <th>POSITION</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{t1[0].nat}</td>
                        <td className="btn-td">0.95</td>
                        <td className="btn-td"></td>
                        <td>0.0</td>
                      </tr>
                      <tr>
                        <td>{t1[1].nat}</td>
                        <td className="btn-td">0.95</td>
                        <td className="btn-td"></td>
                        <td>0.0</td>
                      </tr>
                    </tbody>
                  </table>{" "}
                </div>
              </div>
            ) : null}

            {matchOdds.length ? (
              <div className="pr-box">
                <div
                  className="prbox-header"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <span>PENDING SESSION : Min : 100.0 | Max : 50000.0</span>
                  <span>
                    {playerDetails ? playerDetails.username : ""}(
                    {playerDetails ? playerDetails.name : ""})
                  </span>
                </div>
                <div className="prbox-body odds-table">
                  <table className="normal">
                    <thead>
                      <tr>
                        <th>SESSION</th>
                        <th>NO</th>
                        <th className="b-th">RATE</th>
                        <th
                          style={{
                            background: "#e9e9e9",
                            color: "#444",
                            padding: "auto",
                          }}
                        >
                          YES
                        </th>
                        <th className="b-th">RATE</th>
                        <th>Pos NO</th>
                        <th>Pos Yes</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {matchOdds
                        .sort((a, b) => a.srno - b.srno)
                        .map((odd) => {
                          const yRate = Math.round((odd.bs1 / 100) * 100) / 100;
                          const nRate = Math.round((odd.ls1 / 100) * 100) / 100;
                          const lay = odd.l1;
                          const back = odd.b1;
                          let noSum = 0;
                          let yesSum = 0;
                          let myShareNo = 0;
                          let myShareYes = 0;
                          const cf = betData.filter(
                            (x) => x.fancyName === odd.nat
                          );
                          var OddsSum = 0;
                          const posArr = [];

                          for (var i = 0; i < cf.length; i++) {
                            const currentFancy = cf[i];
                            const {
                              profitAmount,
                              lossAmount,
                              sessionCommission,
                              myCom,
                            } = currentFancy;
                            const pp = -lossAmount + sessionCommission - myCom;
                            const lp = profitAmount + sessionCommission - myCom;
                            OddsSum += currentFancy.odds;
                            const noVal = currentFancy.isBack ? pp : lp;
                            const yesVal = currentFancy.isBack ? lp : pp;
                            myShareNo += noVal;
                            myShareYes += yesVal;
                            posArr.push({
                              odds: currentFancy.odds,
                              yes: yesVal,
                              no: noVal,
                            });
                            noSum += parseFloat(noVal);
                            yesSum += parseFloat(yesVal);
                          }
                          var average = OddsSum / cf.length;
                          average = Math.floor(average)
                            ? Math.floor(average)
                            : 0;
                          const array = [];
                          for (var i = average - 30; i < average + 25; i++) {
                            var numSum = 0;
                            for (var j = 0; j < posArr.length; j++) {
                              const s = posArr[j];
                              if (s.odds >= 0) {
                                numSum += i < s.odds ? s.yes : s.no;
                              }
                            }
                            array.push({ n: i, sum: numSum });
                          }
                          odd["posArr"] = array;
                          return (
                            <tr key={odd.sid}>
                              <td>{odd.nat}</td>
                              {!lay && !back ? (
                                <>
                                  <td className="pd-10 btn-td load-td">
                                    <div className="bold-num"></div>
                                  </td>
                                  <td className="wb-td load-td">
                                    <div className="bold-num"></div>
                                  </td>
                                  <td className="pd-10 btn-td or-clr load-td">
                                    <div className="bold-num"></div>
                                  </td>
                                  <td className="wb-td load-td">
                                    <div className="bold-num b-td"></div>
                                  </td>
                                </>
                              ) : (
                                <>
                                  <td className="pd-10 btn-td">
                                    <div className="bold-num">
                                      {parseFloat(lay)}
                                    </div>
                                  </td>
                                  <td className="wb-td">
                                    <div className="bold-num">{nRate}</div>
                                  </td>
                                  <td className="pd-10 btn-td or-clr">
                                    <div className="bold-num">
                                      {parseFloat(back)}
                                    </div>
                                  </td>
                                  <td className="wb-td">
                                    <div className="bold-num b-td">{yRate}</div>
                                  </td>
                                </>
                              )}
                              <td className="wb-td">
                                <div className="bold-num b-td">
                                  {myShareYes
                                    ? Math.round(myShareYes * 100) / 100
                                    : 0.0}
                                </div>
                              </td>
                              <td className="wb-td">
                                <div className="bold-num b-td">
                                  {myShareNo
                                    ? Math.round(myShareNo * 100) / 100
                                    : 0.0}
                                </div>
                              </td>
                              <td className="wb-td center-btn">
                                <button
                                  onClick={() => setPosData(odd)}
                                  className="pd-btn"
                                >
                                  POSITION
                                </button>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                  <div></div>
                </div>
              </div>
            ) : null}
            {declaredSession.length ? (
              <div className="pr-box">
                <div
                  className="prbox-header"
                  onClick={() => setOpenSTable(!openSTable)}
                >
                  <div className="flex-header">
                    <span>DECLARED SESSION</span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="40"
                      viewBox="0 96 960 960"
                      width="40"
                      style={{ fill: "#fff" }}
                    >
                      <path d="M480 711 240 471l47.333-47.333L480 617.001l192.667-192.667L720 471.667 480 711Z" />
                    </svg>
                  </div>
                </div>
                {openSTable && (
                  <div className="prbox-body tunnel">
                    <table className="normal">
                      <thead>
                        <tr>
                          <th>SESSION</th>
                          <th>Result</th>
                          <th
                            className="flex-td"
                            style={{
                              background: "#E9E9E9",
                              color: "#222",
                            }}
                          >
                            Status
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {declaredSession
                          .sort(function (a, b) {
                            return (
                              convertToTimeStamp(b.createdOn) -
                              convertToTimeStamp(a.createdOn)
                            );
                          })
                          .map((x) => {
                            return (
                              <tr key={x.fancyName}>
                                <td>{x.fancyName}</td>
                                <td
                                  className="wb-td flex-td"
                                  style={{ color: "#555", fontWeight: "bold" }}
                                >
                                  {x.result}
                                </td>
                                <td
                                  className="wb-td flex-td"
                                  style={{ color: "#555", fontWeight: "bold" }}
                                >
                                  {Math.round(x.sum * 100) / 100}
                                </td>
                              </tr>
                            );
                          })}
                        <tr>
                          <td
                            className="wb-td flex-td"
                            style={{ color: "#555", fontWeight: "bold" }}
                            colSpan={2}
                          >
                            Total:
                          </td>
                          <td
                            className="wb-td flex-td"
                            style={{ color: "#555", fontWeight: "bold" }}
                          >
                            {totalStatus}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                )}
              </div>
            ) : null}

            <div className="cnt-tab bgrey">
              {matchList
                .filter((x) => (x.back11 || x.lay11) && x.eventId !== matchId)
                .map((x) => {
                  const matchName = x.eventName;
                  const timestamp = new Date(
                    x.eventName.allReplace({ PM: " PM", AM: " AM" })
                  );
                  const day = timestamp.getDate();
                  const month = timestamp.getMonth();
                  const time = timestamp;
                  return (
                    <Link
                      to={`/liveReport/${x.eventId}`}
                      className="c-card"
                      key={x.eventId}
                    >
                      <div className="c-date">
                        <div className="c-day">{day > 9 ? day : `0${day}`}</div>
                        <div className="c-month">{months[month]}</div>
                        <div className="c-time">
                          {formatAMPM(new Date(time))}
                        </div>
                      </div>
                      <div className="c-main">
                        <p className="card-heading">{matchName}</p>
                      </div>
                    </Link>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PlayerLiveReport;
