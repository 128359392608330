import {
  BrowserRouter as Router,
  Route,
  Routes,
  useNavigate,
} from "react-router-dom";
import "./style/app.css";
import "./style/style.css";
import React, { useEffect, useContext, useCallback } from "react";
import "./style/adminhome.css";
import { AuthProvider, AuthContext } from "./context/AuthContext";
import Main from "./components/Main";
import api_url from "./constants";
import axios from "axios";
import { ReqCallContext, ReqCallProvider } from "./context/ReqCallContext";
import Spinner from "./components/Spinner";
import socketIOClient from "socket.io-client";
const Login = React.lazy(() => import("./components/Login"));
const ENDPOINT = "https://api2.fly247.in";

function App() {
  const [userContext, setUserContext] = useContext(AuthContext);
  const [reqCall, setReqCall] = useContext(ReqCallContext);
  const navigate = useNavigate();

  const verifyUser = async () => {
    try {
      if (userContext.token) {
        const response = await axios.post(
          api_url + "/auth/checkAdminActive",
          {},
          {
            headers: {
              "Content-Type": "application/json",
            },
            withCredentials: true,
          }
        );
        if (response.status === 200) {
          const data = response.data;
          setUserContext((oldValues) => {
            return { ...oldValues, token: data.token };
          });
        }
      }
    } catch (error) {
      if (error.response.status === 401) {
        setUserContext((oldValues) => {
          return { ...oldValues, details: null, token: null };
        });
      }
    }
  };

  useEffect(() => {
    verifyUser();
  }, [reqCall]);
  const syncLogout = useCallback((event) => {
    if (event.key === "adminLogout") {
      window.location.reload();
      navigate("/");
    }
  }, []);
  useEffect(() => {
    window.addEventListener("storage", syncLogout);
    return () => {
      window.removeEventListener("storage", syncLogout);
    };
  }, [syncLogout]);
  useEffect(() => {
    const socket = socketIOClient(ENDPOINT);

    if (userContext.details) {
      if (userContext.details.username && userContext.details.level > 0) {
        socket.emit("login", {
          username: userContext.details.username,
          level: userContext.details.level,
        });
      }
    }
    return () => {
      socket.disconnect();
    };
  }, [userContext.details]);
  return (
    <div className="app">
      <React.Suspense
        fallback={<Spinner color="#222" style={{ marginTop: 20 }} />}
      >
        {userContext.token ? (
          <Main userContext={userContext} setUserContext={setUserContext} />
        ) : (
          <div>
            <div className="content">
              <Routes>
                <Route
                  exact
                  path="/"
                  index
                  element={
                    <React.Suspense
                      fallback={
                        <Spinner color="#222" style={{ marginTop: 20 }} />
                      }
                    >
                      <Login />
                    </React.Suspense>
                  }
                />
                <Route
                  path="*"
                  element={
                    <React.Suspense
                      fallback={
                        <Spinner color="#222" style={{ marginTop: 20 }} />
                      }
                    >
                      <Login />
                    </React.Suspense>
                  }
                />
              </Routes>
            </div>
          </div>
        )}
      </React.Suspense>
    </div>
  );
}
const AppWrapper = () => (
  <AuthProvider>
    <ReqCallProvider>
      <Router>
        <App />
      </Router>
    </ReqCallProvider>
  </AuthProvider>
);
export default AppWrapper;
