import React, { useState, useEffect, useContext } from "react";
import Spinner from "./Spinner";
import axios from "axios";
import { AuthContext } from "../context/AuthContext";
import { useParams, Link } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import api_url from "../constants";
import { format } from "date-fns";
import "../style/odds.css";
import { ReqCallContext, ReqCallProvider } from "../context/ReqCallContext";

function Ledger() {
  const { userId } = useParams();
  const [authContext, setAuthContext] = useContext(AuthContext);
  const [reqCall, setReqCall] = useContext(ReqCallContext);
  const config = {
    headers: { Authorization: `Bearer ${authContext.token}` },
  };
  const now = new Date(Date.now());
  now.setDate(now.getDate() - 10);
  const [startDate, setStartDate] = useState(now);
  const [endDate, setEndDate] = useState(new Date(Date.now()));
  const [loading, setLoading] = useState(true);
  const [userInfo, setUserInfo] = useState({});
  const [data, setData] = useState([]);
  const [userLoading, setUserLoading] = useState(true);
  const fetchUser = async () => {
    const response = await axios.get(
      `${api_url}/auth/getUserByUsername/${userId}`,
      config
    );
    setUserInfo(response.data);
    setUserLoading(false);
  };
  const fetchCompanyLenDen = async () => {
    setLoading(true);
    const stDate = format(startDate, "MM-dd-yy");
    const eDate = format(endDate.setDate(endDate.getDate() + 1), "MM-dd-yy");

    const response = await axios.get(
      `${api_url}/ledger/getLedger/${userId}/${stDate}/${eDate}`,
      config
    );
    setData(response.data);
    setLoading(false);
  };
  useEffect(() => {
    setReqCall(reqCall + 1);
    fetchUser();
    fetchCompanyLenDen();
  }, [userId]);

  function timestampToString(timestamp) {
    const date = new Date(timestamp);
    const formattedDate = date.toLocaleString("en-US", {
      month: "short",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      hour12: true,
    });
    return formattedDate;
  }
  const level = userInfo.level;
  const levelname =
    level === 2
      ? "Super Company"
      : level === 3
      ? "Super Stockist"
      : level === 4
      ? "Stockist"
      : level === 5
      ? "Agent"
      : "";

  if (loading || userLoading) {
    return <Spinner color="#000" style={{ marginTop: 10 }} />;
  }
  return (
    <div>
      <div className="header-container">
        <h1>Agent</h1>
        <Link to={"/"} className="tag-link">
          Dashboard
        </Link>{" "}
        / {levelname} / <span className="uppercase">{userInfo.username}</span> (
        {userInfo.name.trim()}) / <b>Ledger</b>
      </div>
      <div className="filter-table">
        <input
          type={"date"}
          onChange={(e) => {
            const selectedDate = new Date(e.target.value);
            if (!isNaN(selectedDate.getTime())) {
              setStartDate(selectedDate);
            }
          }}
          value={startDate ? format(startDate, "yyyy-MM-dd") : ""}
        />

        <input
          type={"date"}
          onChange={(e) => {
            const selectedDate = new Date(e.target.value);
            if (!isNaN(selectedDate.getTime())) {
              setEndDate(selectedDate);
            }
          }}
          value={endDate ? format(endDate, "yyyy-MM-dd") : ""}
        />
        <button onClick={() => fetchCompanyLenDen()}>Search</button>
      </div>
      <div className="box-container">
        <div className="box" style={{ maxWidth: "100%" }}>
          <div
            className="box-header"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <span>Client Ledger</span>
          </div>
          <div className="box-body">
            <table style={{ width: "100%" }} className="flex-table">
              <thead>
                <tr>
                  <th>DATE/TIME</th>
                  <th>Collection Name</th>
                  <th>DEBIT</th>
                  <th>CREDIT</th>
                  <th>Balance</th>
                  <th>Note</th>
                </tr>
              </thead>
              <tbody>
                {data
                  .sort((a, b) => b.i - a.i)
                  .map((x) => {
                    const val = x.totalProfitLoss;
                    const credit = val >= 0 ? Math.abs(val).toFixed(1) : "-";
                    const debit = val < 0 ? Math.abs(val).toFixed(1) : "-";
                    return (
                      <tr key={x.matchname + x.startTime}>
                        <td>{timestampToString(x.startTime)}</td>
                        <td>
                          <div className="maxline">{x.matchName}</div>
                        </td>
                        <td>{debit}</td>
                        <td>{credit}</td>
                        <td>{x.balance ? x.balance.toFixed(1) : ""}</td>
                        <td>{x.note ? x.note : "-"}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Ledger;
