import React, { useContext, useState } from "react";
import Spinner from "./Spinner";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { AuthContext } from "../context/AuthContext";
import api_url from "../constants";
import { Link, useParams } from "react-router-dom";
import { ReqCallContext, ReqCallProvider } from "../context/ReqCallContext";

function CreateMessage({ currentMessage, setNewMessage }) {
  const [userContext, setUserContext] = useContext(AuthContext);
  const [reqCall, setReqCall] = useContext(ReqCallContext);
  const [cuLoading, setCuLoading] = useState(false);
  const [adLoading, setAdLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [userMessage, setUserMessage] = useState("");
  const [newUserMessage, setNewUserMessage] = useState("");
  const [err, setError] = useState("");

  const fetchMessage = async () => {
    const response = await axios.get(`${api_url}/api/getAdminMessage`);
    setNewMessage(response.data.message);
    const response2 = await axios.get(`${api_url}/api/getMessage`);
    setNewUserMessage(response2.data.message);
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      setError("");
      setAdLoading(true);
      const response = await axios.post(
        api_url + "/api/setAdminMessage",
        {
          message,
        },
        {
          headers: {
            Authorization: `Bearer ${userContext.token}`,
          },
        }
      );

      if (response.data.status) {
        fetchMessage();
        setMessage("");
        toast.success("Message updated successfully");
      } else {
        setError(response.data.msg);
      }
    } catch (error) {
      console.error(error);
      setError(
        error.response.data.msg ? error.response.data.msg : error.message
      );
    }
    setMessage("");
    setAdLoading(false);
  };
  const handleUserSubmit = async (event) => {
    event.preventDefault();
    try {
      setError("");
      setCuLoading(true);
      const response = await axios.post(
        api_url + "/api/setMessage",
        {
          message: userMessage,
        },
        {
          headers: {
            Authorization: `Bearer ${userContext.token}`,
          },
        }
      );

      if (response.data.status) {
        fetchMessage();
        setMessage("");
        toast.success("Message updated successfully");
      } else {
        setError(response.data.msg);
      }
    } catch (error) {
      console.error(error);
      setError(
        error.response.data.msg ? error.response.data.msg : error.message
      );
    }
    setMessage("");
    setCuLoading(false);
  };
  return (
    <div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <div className="header-container">
        <h1>Create Message</h1>
        <div>
          {" "}
          <Link to={"/"} className="tag-link">
            Dashboard
          </Link>{" "}
          / Message
        </div>
      </div>
      <div className="box-container">
        <div className="box" style={{ maxWidth: "100%" }}>
          <div
            className="box-header"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <span>Create Message</span>
          </div>
          {err ? <div className="error-message">{err}</div> : ""}

          <form
            className="box-body"
            style={{ paddingTop: "10px" }}
            method="post"
            onSubmit={handleSubmit}
          >
            <div className="flex-container">
              <div className="flex-elem">Admin Message:</div>
              <div> {currentMessage}</div>
            </div>
            <div className="flex-container">
              <div className="flex-elem">Message</div>
              <div className="input-cnt ">
                <textarea
                  placeholder="New Message"
                  type="password"
                  autoComplete="new-password"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                />
              </div>
            </div>

            <div className="flex-container">
              <div className="flex-elem"></div>
              <div className="input-cnt">
                <button name="newUser" type={cuLoading ? "button" : "submit"}>
                  {adLoading ? (
                    <Spinner color="#fff" />
                  ) : (
                    "Publish Admin Message"
                  )}
                </button>
              </div>
            </div>
          </form>
          <form
            className="box-body"
            style={{ paddingTop: "10px" }}
            method="post"
            onSubmit={handleUserSubmit}
          >
            <div className="flex-container">
              <div className="flex-elem">User Message:</div>
              <div> {newUserMessage}</div>
            </div>
            <div className="flex-container">
              <div className="flex-elem">Message</div>
              <div className="input-cnt ">
                <textarea
                  placeholder="New Message"
                  type="password"
                  autoComplete="new-password"
                  value={userMessage}
                  onChange={(e) => setUserMessage(e.target.value)}
                />
              </div>
            </div>

            <div className="flex-container">
              <div className="flex-elem"></div>
              <div className="input-cnt">
                <button name="newUser" type={cuLoading ? "button" : "submit"}>
                  {cuLoading ? (
                    <Spinner color="#fff" />
                  ) : (
                    "Publish User Message"
                  )}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default CreateMessage;
