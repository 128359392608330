import React, { useEffect, useState, useCallback, useContext } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useNavigate,
} from "react-router-dom";
import GridViewRoundedIcon from "@mui/icons-material/GridViewRounded";
import SportsBasketballRoundedIcon from "@mui/icons-material/SportsBasketballRounded";
import CasinoRoundedIcon from "@mui/icons-material/CasinoRounded";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import {
  AssessmentOutlined,
  BlockRounded,
  ClassRounded,
  ContactPageRounded,
  LanguageRounded,
  LockRounded,
  MarkUnreadChatAlt,
  PeopleAlt,
  PeopleRounded,
  RefreshOutlined,
} from "@mui/icons-material";
import axios from "axios";
import api_url from "../constants";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../style/single.css";
import List from "@mui/material/List";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import GroupsIcon from "@mui/icons-material/Groups";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import ReceiveCash from "./ReceiveCash";
import PayCash from "./PayCash";
import Ledger from "./Ledger";
import ChangePassword from "./ChangePassword";
import CreateMessage from "./CreateMessage";
import CashLedger from "./CashLedger";
import MatchLedger from "./MatchLedger";
import LiveMatches from "./LiveMatches";
import BlockedClients from "./BlockedClients";
import DirectClients from "./DirectClients";
import DirectAgents from "./DirectAgents";
import ColorGame from "./ColorGame";
import ClientInfo from "./ClientInfo";
import PlayerLiveReport from "./PlayerLiveReport";
import PlayerList from "./PlayerList";
import IsActive from "./IsActive";
const Spinner = React.lazy(() => import("./Spinner"));
const Dashboard = React.lazy(() => import("./Dashboard"));
const SideBarItem = React.lazy(() => import("./SideBarItem"));
const UserDashboard = React.lazy(() => import("./UserDashboard"));
const Matches = React.lazy(() => import("./Matches"));
const LiveCasino = React.lazy(() => import("./LiveCasino"));
const CasinoResults = React.lazy(() => import("./CasinoResults"));
const BlockMarket = React.lazy(() => import("./BlockMarket"));
const ManageClient = React.lazy(() => import("./ManageClient"));
const ManagePassword = React.lazy(() => import("./ManagePassword"));
const Language = React.lazy(() => import("./Language"));
const ManageLedger = React.lazy(() => import("./ManageLedger"));
const CreateClients = React.lazy(() => import("./CreateClients"));
const Agent = React.lazy(() => import("./Agent"));
const Stockist = React.lazy(() => import("./Stockist"));
const CreateStockist = React.lazy(() => import("./CreateStockist"));
const CreateAgent = React.lazy(() => import("./CreateAgent"));
const CreateSuperCompany = React.lazy(() => import("./CreateSuperCompany"));
const CreateSuperStockist = React.lazy(() => import("./CreateSuperStockist"));
const SuperCompany = React.lazy(() => import("./SuperCompany"));
const SuperStockist = React.lazy(() => import("./SuperStockist"));
const CommisionsAndLimits = React.lazy(() => import("./CommisionsAndLimits"));
const MatchDetails = React.lazy(() => import("./MatchDetails"));
const Manager = React.lazy(() => import("./Manager"));
const CreateManager = React.lazy(() => import("./CreateManager"));
const SessionBetSlip = React.lazy(() => import("./SessionBetSlip"));
const EditDetails = React.lazy(() => import("./EditDetails"));
const LiveReport = React.lazy(() => import("./LiveReport"));
const CoinHistory = React.lazy(() => import("./CoinHistory"));
const BetSlips = React.lazy(() => import("./BetSlips"));
const CollectionReport = React.lazy(() => import("./CollectionReport"));
const Ledgers = React.lazy(() => import("./Ledgers"));
const ProfitAndLoss = React.lazy(() => import("./ProfitAndLoss"));
const ClientReport = React.lazy(() => import("./ClientReport"));
const CompanyReport = React.lazy(() => import("./CompanyReport"));
const SessionEarningReport = React.lazy(() => import("./SessionEarningReport"));

function Main(props) {
  const [open, setOpen] = useState(false);
  const [clientOpen, setClientOpen] = useState(false);
  const [ledgerOpen, setLedgerOpen] = useState(false);
  const [dataLoading, setDataLoading] = useState(true);
  const [message, setMessage] = useState("");
  const { userContext, setUserContext } = props;
  const [countLoading, setCountLoading] = useState(false);
  const navigate = useNavigate();

  const logoutHandler = async () => {
    try {
      await axios.get(api_url + "/auth/adminLogout", {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userContext.token}`,
        },
      });

      setUserContext((oldValues) => {
        return { ...oldValues, details: undefined, token: null };
      });
      // Set a timestamp in local storage
      window.localStorage.setItem("logout", Date.now());
      navigate("/");
    } catch (error) {
      console.error("Error during logout:", error);
    }
  };

  const fetchUserDetails = useCallback(async () => {
    try {
      const response = await axios.get(`${api_url}/auth/me`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userContext.token}`,
        },
        withCredentials: true,
        validateStatus: function (status) {
          return status >= 200; // Treat any status code in the 2xx range as a success
        },
      });

      if (response.status === 200) {
        const data = response.data;
        setUserContext((oldValues) => {
          return { ...oldValues, details: data };
        });
      } else {
        if (response.status === 401) {
          setUserContext((oldValues) => {
            return { ...oldValues, details: null, token: null };
          });
        }
        // Handle non-200 status codes here if needed
        // For example, you can show an error message to the user
      }
    } catch (error) {
      // Handle network or other errors here
      console.error("An error occurred:", error);
      setDataLoading(false);
      return;
    } finally {
      setDataLoading(false);
    }
  }, [setUserContext, userContext.token]);
  const fetchMessage = async () => {
    const response = await axios.get(`${api_url}/api/getAdminMessage`);
    setMessage(response.data.message);
  };
  const fetchOnline = async () => {
    setCountLoading(true);
    const response = await axios.get(`${api_url}/api/getOnlineCount`);
    setMessage(response.data.count);
    setCountLoading(false);
  };
  useEffect(() => {
    // Fetch only when user details are not present
    fetchUserDetails();
  }, [userContext.token]);
  useEffect(() => {
    if (userContext.details) {
      if (userContext.details.username === "cc0001") {
        fetchOnline();
      } else {
        fetchMessage();
      }
    }
  }, [userContext.details]);
  const level = userContext.details ? userContext.details.level : null;
  const isAgent = level < 6 && level > 0;
  const handleClick = () => {
    setOpen(!open);
    if (!open) {
      setClientOpen(false);
      setLedgerOpen(false);
    }
  };
  const handleClients = () => {
    setClientOpen(!clientOpen);
    if (!clientOpen) {
      setOpen(false);
      setLedgerOpen(false);
    }
  };
  const handleLedger = () => {
    setLedgerOpen(!ledgerOpen);
    if (!ledgerOpen) {
      setClientOpen(false);
      setOpen(false);
    }
  };
  const close = () => {
    if (window.innerWidth < 992) {
      const mainSite = document.getElementById("mainsite");
      mainSite.classList.remove("active");
    }
  };
  const toggleNavBar = () => {
    const mainSite = document.getElementById("mainsite");
    if (mainSite.classList.contains("active")) {
      mainSite.classList.remove("active");
    } else {
      mainSite.classList.add("active");
    }
  };

  if (dataLoading) {
    return <Spinner color="#222" style={{ marginTop: 20 }} />;
  }
  return (
    <div>
      <section className="mainsite" id="mainsite">
        <nav className="sidenav">
          <div className="menu">
            <div className="profile-side">
              {userContext.details.username}({userContext.details.name})
            </div>

            <ul className="menu-list">
              <SideBarItem
                visible={isAgent}
                title="Dashboard"
                Icon={GridViewRoundedIcon}
                path="/"
                onClick={() => close()}
              />
              {level === 1 ? (
                <SideBarItem
                  visible={isAgent}
                  title="Client Info"
                  Icon={PeopleAlt}
                  path="/clientInfo"
                  onClick={() => close()}
                />
              ) : null}
              {level < 6 && (
                <List
                  sx={{
                    width: "100%",
                    maxWidth: 360,
                    bgcolor: "#1b1c1c",
                    color: "#fff",
                    padding: 0,
                  }}
                  component="nav"
                  aria-labelledby="nested-list-subheader"
                >
                  <SideBarItem
                    visible={isAgent && level !== 5}
                    title="Manage"
                    Icon={GroupsIcon}
                    style={{
                      backgroundColor: open ? "#005b85" : "#1b1c1c",
                    }}
                    onClick={handleClick}
                    LeftIcon={open ? ExpandLess : ExpandMore}
                  />
                  <Collapse in={open} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      <SideBarItem
                        path="/superCompany"
                        title="Super Company"
                        style={{
                          paddingLeft: 50,
                          backgroundColor: "rgba(0,0,0,0.4)",
                        }}
                        onClick={() => close()}
                        Icon={GroupsIcon}
                        visible={level === 1}
                      />
                      <SideBarItem
                        path="/superStockist"
                        title="Super Stockist"
                        style={{
                          paddingLeft: 50,
                          backgroundColor: "rgba(0,0,0,0.4)",
                        }}
                        onClick={() => close()}
                        Icon={GroupsIcon}
                        visible={level === 1 || level === 2}
                      />
                      <SideBarItem
                        path="/stockist"
                        title="Stockist"
                        style={{
                          paddingLeft: 50,
                          backgroundColor: "rgba(0,0,0,0.4)",
                        }}
                        onClick={() => close()}
                        Icon={GroupsIcon}
                        visible={level === 1 || level === 2 || level === 3}
                      />
                      <SideBarItem
                        path="/agent"
                        title="Agent"
                        style={{
                          paddingLeft: 50,
                          backgroundColor: "rgba(0,0,0,0.4)",
                        }}
                        onClick={() => close()}
                        Icon={GroupsIcon}
                        visible={
                          level === 1 ||
                          level === 2 ||
                          level === 3 ||
                          level === 4
                        }
                      />
                      {/* <SideBarItem
                        path="/Manager"
                        title="Manager"
                        style={{
                          paddingLeft: 50,
                          backgroundColor: "rgba(0,0,0,0.4)",
                        }}
                        onClick={() => close()}
                        Icon={GroupsIcon}
                        visible={level === 1}
                      /> */}
                    </List>
                  </Collapse>
                </List>
              )}
              <SideBarItem
                visible={isAgent}
                title="Live Matches"
                path="/liveMatches"
                onClick={() => close()}
                Icon={SportsBasketballRoundedIcon}
              />
              <SideBarItem
                visible={isAgent}
                title="Completed Matches"
                path="/matches"
                onClick={() => close()}
                Icon={SportsBasketballRoundedIcon}
              />

              <SideBarItem
                visible={isAgent}
                title="Color Game"
                Icon={CasinoRoundedIcon}
                path="/colorGame"
                onClick={() => close()}
              />
              <SideBarItem
                visible={isAgent}
                title="Live Casino"
                Icon={CasinoRoundedIcon}
                path="/liveCasino"
                onClick={() => close()}
              />

              <SideBarItem
                visible={isAgent}
                path="/casinoResult"
                title="Check Casino Results"
                Icon={SearchRoundedIcon}
                onClick={() => close()}
              />
              <SideBarItem
                visible={isAgent}
                title="Block Market"
                Icon={BlockRounded}
                onClick={() => close()}
                path="/blockMarket"
              />

              <List
                sx={{
                  width: "100%",
                  maxWidth: 360,
                  bgcolor: "#1b1c1c",
                  color: "#fff",
                  padding: 0,
                }}
                component="nav"
                aria-labelledby="nested-list-subheader"
              >
                <SideBarItem
                  noBorder={clientOpen}
                  visible={isAgent}
                  title="Manage Clients"
                  Icon={GroupsIcon}
                  style={{
                    backgroundColor: clientOpen ? "#005b85" : "#1b1c1c",
                  }}
                  onClick={handleClients}
                  LeftIcon={clientOpen ? ExpandLess : ExpandMore}
                />
                <Collapse in={clientOpen} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    {userContext.details.level !== 1 && (
                      <>
                        <SideBarItem
                          visible={isAgent}
                          title="My Clients"
                          Icon={PeopleRounded}
                          onClick={() => close()}
                          path="/manageClient"
                          style={{
                            paddingLeft: 50,
                            backgroundColor: "rgba(0,0,0,0.4)",
                          }}
                        />
                        <SideBarItem
                          visible={isAgent}
                          title="Blocked Clients"
                          Icon={PeopleRounded}
                          onClick={() => close()}
                          path="/blockedClients"
                          style={{
                            paddingLeft: 50,
                            backgroundColor: "rgba(0,0,0,0.4)",
                          }}
                        />
                      </>
                    )}
                    <SideBarItem
                      visible={isAgent}
                      title="Commision & Limits"
                      Icon={CurrencyRupeeIcon}
                      onClick={() => close()}
                      path="/commisionAndLimits"
                      style={{
                        paddingLeft: 50,
                        backgroundColor: "rgba(0,0,0,0.4)",
                      }}
                    />
                  </List>
                </Collapse>
              </List>
              <SideBarItem
                visible={isAgent}
                title="Manage Password"
                onClick={() => close()}
                Icon={LockRounded}
                path="/managePassword"
              />
              <SideBarItem
                visible={isAgent}
                title="Language"
                onClick={() => close()}
                Icon={LanguageRounded}
                path="/language"
              />
              <List
                sx={{
                  width: "100%",
                  maxWidth: 360,
                  bgcolor: "#1b1c1c",
                  color: "#fff",
                  padding: 0,
                }}
                component="nav"
                aria-labelledby="nested-list-subheader"
              >
                <SideBarItem
                  visible={isAgent}
                  title="Manage Ledger"
                  Icon={ClassRounded}
                  style={{
                    backgroundColor: ledgerOpen ? "#005b85" : "#1b1c1c",
                  }}
                  noBorder={ledgerOpen}
                  onClick={handleLedger}
                  LeftIcon={ledgerOpen ? ExpandLess : ExpandMore}
                />
                <Collapse in={ledgerOpen} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    <SideBarItem
                      visible={isAgent}
                      title="Collection Report"
                      Icon={ContactPageRounded}
                      onClick={() => close()}
                      path="/collectionReport"
                      style={{
                        paddingLeft: 50,
                        backgroundColor: "rgba(0,0,0,0.4)",
                      }}
                    />
                    <SideBarItem
                      visible={isAgent && level !== 1}
                      title="Company Len-Den"
                      Icon={CurrencyRupeeIcon}
                      onClick={() => close()}
                      path="/ledgers"
                      style={{
                        paddingLeft: 50,
                        backgroundColor: "rgba(0,0,0,0.4)",
                      }}
                    />
                    <SideBarItem
                      visible={isAgent}
                      title="In-Out"
                      Icon={AssessmentOutlined}
                      onClick={() => close()}
                      path={`/statementByUser/${userContext.details.username}`}
                      style={{
                        paddingLeft: 50,
                        backgroundColor: "rgba(0,0,0,0.4)",
                      }}
                    />
                    <SideBarItem
                      visible={isAgent}
                      title="P & L"
                      Icon={CurrencyRupeeIcon}
                      onClick={() => close()}
                      path={`/profitAndLoss/${userContext.details.username}`}
                      style={{
                        paddingLeft: 50,
                        backgroundColor: "rgba(0,0,0,0.4)",
                      }}
                    />
                  </List>
                </Collapse>

                <SideBarItem
                  visible={level === 1}
                  title="Message"
                  onClick={() => close()}
                  Icon={MarkUnreadChatAlt}
                  path="/createMessage"
                />
              </List>
            </ul>
          </div>
        </nav>
        <section className="mainpage" id="mainpage">
          <div className="burger" id="burger" onClick={() => toggleNavBar()}>
            <div className="burger-menu"></div>
          </div>
          <div className="container">
            <header>
              <div className="right-menu">
                <div style={{ display: "flex", paddingLeft: 10 }}>
                  <div>
                    <span className="c-logo">
                      <img src={require("../images/logo.png")} alt="logo" />
                    </span>
                  </div>
                </div>
              </div>
              <div className="flex__align">
                <button
                  className="dashboard__btn logout__btn"
                  onClick={() => {
                    logoutHandler();
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="40"
                    width="40"
                  >
                    <path d="M20 21.708q-.833 0-1.417-.583Q18 20.542 18 19.667V5.833q0-.833.583-1.437.584-.604 1.417-.604.833 0 1.438.604.604.604.604 1.437v13.834q0 .875-.604 1.458-.605.583-1.438.583Zm0 14.417q-3.333 0-6.271-1.271-2.937-1.271-5.104-3.458-2.167-2.188-3.437-5.104-1.271-2.917-1.271-6.25 0-2.959 1.021-5.667 1.02-2.708 3.02-4.875.542-.667 1.438-.729.896-.063 1.521.521.583.625.562 1.416-.021.792-.479 1.417-1.5 1.667-2.271 3.688-.771 2.02-.771 4.229 0 5 3.5 8.541 3.5 3.542 8.542 3.542t8.542-3.542q3.5-3.541 3.5-8.541 0-2.25-.792-4.271T29 12.083q-.458-.666-.458-1.458 0-.792.583-1.333.625-.625 1.542-.5.916.125 1.583.833 1.875 2.167 2.854 4.833.979 2.667.979 5.584 0 3.333-1.271 6.25-1.27 2.916-3.437 5.104-2.167 2.187-5.104 3.458-2.938 1.271-6.271 1.271Z" />
                  </svg>
                  <span className="svgBtn_text">LOGOUT</span>
                </button>
              </div>
            </header>
            <div
              className="marquee"
              onClick={() => {
                if (userContext.details.username === "cc0001") {
                  fetchOnline();
                }
              }}
            >
              {userContext.details.username === "cc0001" ? (
                <span
                  style={{
                    padding: "0 10px",
                    textAlign: "center",
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    height: 35,
                    justifyContent: "center",
                  }}
                >
                  <span>{message} Online</span>
                  <span
                    className={countLoading ? "spin" : ""}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <RefreshOutlined style={{ fontSize: 35 }} />
                  </span>
                </span>
              ) : (
                <marquee>{message ? message : "Welcome"}</marquee>
              )}
            </div>
            <div className="wrapper">
              <Routes>
                <Route
                  exact
                  path="/"
                  index
                  element={
                    <React.Suspense
                      fallback={
                        <Spinner color="#222" style={{ marginTop: 20 }} />
                      }
                    >
                      <Dashboard
                        userData={userContext}
                        dataLoading={dataLoading}
                      />
                    </React.Suspense>
                  }
                />
                <Route
                  exact
                  path="/clientReport/:matchId"
                  index
                  element={
                    <React.Suspense
                      fallback={
                        <Spinner color="#222" style={{ marginTop: 20 }} />
                      }
                    >
                      <ClientReport />
                    </React.Suspense>
                  }
                />
                <Route
                  exact
                  path="/matches"
                  index
                  element={
                    <React.Suspense
                      fallback={
                        <Spinner color="#222" style={{ marginTop: 20 }} />
                      }
                    >
                      <Matches userData={userContext} />
                    </React.Suspense>
                  }
                />
                <Route
                  exact
                  path="/liveMatches"
                  index
                  element={
                    <React.Suspense
                      fallback={
                        <Spinner color="#222" style={{ marginTop: 20 }} />
                      }
                    >
                      <LiveMatches userData={userContext} />
                    </React.Suspense>
                  }
                />
                <Route
                  exact
                  path="/liveCasino"
                  index
                  element={
                    <React.Suspense
                      fallback={
                        <Spinner color="#222" style={{ marginTop: 20 }} />
                      }
                    >
                      <LiveCasino />
                    </React.Suspense>
                  }
                />
                <Route
                  exact
                  path="/clientInfo"
                  index
                  element={
                    <React.Suspense
                      fallback={
                        <Spinner color="#222" style={{ marginTop: 20 }} />
                      }
                    >
                      <ClientInfo />
                    </React.Suspense>
                  }
                />
                <Route
                  exact
                  path="/colorGame"
                  index
                  element={
                    <React.Suspense
                      fallback={
                        <Spinner color="#222" style={{ marginTop: 20 }} />
                      }
                    >
                      <ColorGame />
                    </React.Suspense>
                  }
                />
                <Route
                  exact
                  path="/ledgers"
                  index
                  element={
                    <React.Suspense
                      fallback={
                        <Spinner color="#222" style={{ marginTop: 20 }} />
                      }
                    >
                      <Ledgers />
                    </React.Suspense>
                  }
                />
                <Route
                  exact
                  path="/profitAndLoss/:userId"
                  index
                  element={
                    <React.Suspense
                      fallback={
                        <Spinner color="#222" style={{ marginTop: 20 }} />
                      }
                    >
                      <ProfitAndLoss />
                    </React.Suspense>
                  }
                />
                <Route
                  exact
                  path="/receiveCash/:userId"
                  index
                  element={
                    <React.Suspense
                      fallback={
                        <Spinner color="#222" style={{ marginTop: 20 }} />
                      }
                    >
                      <ReceiveCash />
                    </React.Suspense>
                  }
                />
                <Route
                  exact
                  path="/payCash/:userId"
                  index
                  element={
                    <React.Suspense
                      fallback={
                        <Spinner color="#222" style={{ marginTop: 20 }} />
                      }
                    >
                      <PayCash />
                    </React.Suspense>
                  }
                />
                <Route
                  exact
                  path="/ledgerShow/:userId"
                  index
                  element={
                    <React.Suspense
                      fallback={
                        <Spinner color="#222" style={{ marginTop: 20 }} />
                      }
                    >
                      <Ledger />
                    </React.Suspense>
                  }
                />
                <Route
                  exact
                  path="/matchLedger/:userId"
                  index
                  element={
                    <React.Suspense
                      fallback={
                        <Spinner color="#222" style={{ marginTop: 20 }} />
                      }
                    >
                      <MatchLedger />
                    </React.Suspense>
                  }
                />
                <Route
                  exact
                  path="/cashLedger/:userId"
                  index
                  element={
                    <React.Suspense
                      fallback={
                        <Spinner color="#222" style={{ marginTop: 20 }} />
                      }
                    >
                      <CashLedger />
                    </React.Suspense>
                  }
                />
                <Route
                  exact
                  path="/casinoResults"
                  index
                  element={
                    <React.Suspense
                      fallback={
                        <Spinner color="#222" style={{ marginTop: 20 }} />
                      }
                    >
                      <CasinoResults />
                    </React.Suspense>
                  }
                />
                <Route
                  exact
                  path="/superStockist"
                  index
                  element={
                    <React.Suspense
                      fallback={
                        <Spinner color="#222" style={{ marginTop: 20 }} />
                      }
                    >
                      <SuperStockist userData={userContext} />
                    </React.Suspense>
                  }
                />
                <Route
                  exact
                  path="/stockist"
                  index
                  element={
                    <React.Suspense
                      fallback={
                        <Spinner color="#222" style={{ marginTop: 20 }} />
                      }
                    >
                      <Stockist userData={userContext} />
                    </React.Suspense>
                  }
                />
                <Route
                  exact
                  path="/manager"
                  index
                  element={
                    <React.Suspense
                      fallback={
                        <Spinner color="#222" style={{ marginTop: 20 }} />
                      }
                    >
                      <Manager userData={userContext} />
                    </React.Suspense>
                  }
                />
                <Route
                  exact
                  path="/matchDetails/:matchId"
                  index
                  element={
                    <React.Suspense
                      fallback={
                        <Spinner color="#222" style={{ marginTop: 20 }} />
                      }
                    >
                      <MatchDetails />
                    </React.Suspense>
                  }
                />
                <Route
                  exact
                  path="/collectionReport/:matchId"
                  index
                  element={
                    <React.Suspense
                      fallback={
                        <Spinner color="#222" style={{ marginTop: 20 }} />
                      }
                    >
                      <CollectionReport />
                    </React.Suspense>
                  }
                />
                <Route
                  exact
                  path="/sessionEarningReport/:matchId"
                  index
                  element={
                    <React.Suspense
                      fallback={
                        <Spinner color="#222" style={{ marginTop: 20 }} />
                      }
                    >
                      <SessionEarningReport userData={userContext} />
                    </React.Suspense>
                  }
                />
                <Route
                  exact
                  path="/companyReport/:matchId"
                  index
                  element={
                    <React.Suspense
                      fallback={
                        <Spinner color="#222" style={{ marginTop: 20 }} />
                      }
                    >
                      <CompanyReport userData={userContext} />
                    </React.Suspense>
                  }
                />
                <Route
                  exact
                  path="/collectionReport"
                  index
                  element={
                    <React.Suspense
                      fallback={
                        <Spinner color="#222" style={{ marginTop: 20 }} />
                      }
                    >
                      <CollectionReport userData={userContext} />
                    </React.Suspense>
                  }
                />
                <Route
                  exact
                  path="/betSlips/:matchId"
                  index
                  element={
                    <React.Suspense
                      fallback={
                        <Spinner color="#222" style={{ marginTop: 20 }} />
                      }
                    >
                      <BetSlips />
                    </React.Suspense>
                  }
                />
                <Route
                  exact
                  path="/sessionBetSlips/:matchId"
                  index
                  element={
                    <React.Suspense
                      fallback={
                        <Spinner color="#222" style={{ marginTop: 20 }} />
                      }
                    >
                      <SessionBetSlip userData={userContext} />
                    </React.Suspense>
                  }
                />
                <Route
                  exact
                  path="/liveReport/:matchId"
                  index
                  element={
                    <React.Suspense
                      fallback={
                        <Spinner color="#222" style={{ marginTop: 20 }} />
                      }
                    >
                      <LiveReport userData={userContext} />
                    </React.Suspense>
                  }
                />
                <Route
                  exact
                  path="/playerList/:matchId"
                  index
                  element={
                    <React.Suspense
                      fallback={
                        <Spinner color="#222" style={{ marginTop: 20 }} />
                      }
                    >
                      <PlayerList userData={userContext} />
                    </React.Suspense>
                  }
                />
                <Route
                  exact
                  path="/playerLiveReport/:matchId/:playerId"
                  index
                  element={
                    <React.Suspense
                      fallback={
                        <Spinner color="#222" style={{ marginTop: 20 }} />
                      }
                    >
                      <PlayerLiveReport userData={userContext} />
                    </React.Suspense>
                  }
                />
                <Route
                  exact
                  path="/statementByUser/:userId"
                  index
                  element={
                    <React.Suspense
                      fallback={
                        <Spinner color="#222" style={{ marginTop: 20 }} />
                      }
                    >
                      <CoinHistory userData={userContext} />
                    </React.Suspense>
                  }
                />
                <Route
                  exact
                  path="/directClients/:userId"
                  index
                  element={
                    <React.Suspense
                      fallback={
                        <Spinner color="#222" style={{ marginTop: 20 }} />
                      }
                    >
                      <DirectClients />
                    </React.Suspense>
                  }
                />
                <Route
                  exact
                  path="/directAgents/:userId"
                  index
                  element={
                    <React.Suspense
                      fallback={
                        <Spinner color="#222" style={{ marginTop: 20 }} />
                      }
                    >
                      <DirectAgents />
                    </React.Suspense>
                  }
                />
                <Route
                  exact
                  path="/editDetails/:userId"
                  index
                  element={
                    <React.Suspense
                      fallback={
                        <Spinner color="#222" style={{ marginTop: 20 }} />
                      }
                    >
                      <EditDetails userData={userContext} />
                    </React.Suspense>
                  }
                />
                <Route
                  exact
                  path="/superCompany"
                  index
                  element={
                    <React.Suspense
                      fallback={
                        <Spinner color="#222" style={{ marginTop: 20 }} />
                      }
                    >
                      <SuperCompany userData={userContext} />
                    </React.Suspense>
                  }
                />
                <Route
                  exact
                  path="/Agent"
                  index
                  element={
                    <React.Suspense
                      fallback={
                        <Spinner color="#222" style={{ marginTop: 20 }} />
                      }
                    >
                      <Agent userData={userContext} />
                    </React.Suspense>
                  }
                />
                <Route
                  exact
                  path="/createManager"
                  index
                  element={
                    <React.Suspense
                      fallback={
                        <Spinner color="#222" style={{ marginTop: 20 }} />
                      }
                    >
                      <CreateManager userData={userContext} />
                    </React.Suspense>
                  }
                />
                <Route
                  exact
                  path="/commisionAndLimits"
                  index
                  element={
                    <React.Suspense
                      fallback={
                        <Spinner color="#222" style={{ marginTop: 20 }} />
                      }
                    >
                      <CommisionsAndLimits userData={userContext} />
                    </React.Suspense>
                  }
                />
                <Route
                  exact
                  path="/isActive"
                  index
                  element={
                    <React.Suspense
                      fallback={
                        <Spinner color="#222" style={{ marginTop: 20 }} />
                      }
                    >
                      <IsActive />
                    </React.Suspense>
                  }
                />
                <Route
                  exact
                  path="/blockMarket"
                  index
                  element={
                    <React.Suspense
                      fallback={
                        <Spinner color="#222" style={{ marginTop: 20 }} />
                      }
                    >
                      <BlockMarket />
                    </React.Suspense>
                  }
                />
                <Route
                  exact
                  path="/manageClient"
                  index
                  element={
                    <React.Suspense
                      fallback={
                        <Spinner color="#222" style={{ marginTop: 20 }} />
                      }
                    >
                      <ManageClient userData={userContext} />
                    </React.Suspense>
                  }
                />
                <Route
                  exact
                  path="/blockedClients"
                  index
                  element={
                    <React.Suspense
                      fallback={
                        <Spinner color="#222" style={{ marginTop: 20 }} />
                      }
                    >
                      <BlockedClients userData={userContext} />
                    </React.Suspense>
                  }
                />
                <Route
                  exact
                  path="/casinoResult"
                  index
                  element={
                    <React.Suspense
                      fallback={
                        <Spinner color="#222" style={{ marginTop: 20 }} />
                      }
                    >
                      <CasinoResults />
                    </React.Suspense>
                  }
                />
                <Route
                  exact
                  path="/managePassword"
                  index
                  element={
                    <React.Suspense
                      fallback={
                        <Spinner color="#222" style={{ marginTop: 20 }} />
                      }
                    >
                      <ManagePassword />
                    </React.Suspense>
                  }
                />
                <Route
                  exact
                  path="/passwordEdit/:username"
                  index
                  element={
                    <React.Suspense
                      fallback={
                        <Spinner color="#222" style={{ marginTop: 20 }} />
                      }
                    >
                      <ChangePassword />
                    </React.Suspense>
                  }
                />
                <Route
                  exact
                  path="/language"
                  index
                  element={
                    <React.Suspense
                      fallback={
                        <Spinner color="#222" style={{ marginTop: 20 }} />
                      }
                    >
                      <Language />
                    </React.Suspense>
                  }
                />
                <Route
                  exact
                  path="/manageLedger"
                  index
                  element={
                    <React.Suspense
                      fallback={
                        <Spinner color="#222" style={{ marginTop: 20 }} />
                      }
                    >
                      <ManageLedger />
                    </React.Suspense>
                  }
                />
                <Route
                  exact
                  path="/createClient"
                  index
                  element={
                    <React.Suspense
                      fallback={
                        <Spinner color="#222" style={{ marginTop: 20 }} />
                      }
                    >
                      <CreateClients userData={userContext} />
                    </React.Suspense>
                  }
                />
                <Route
                  exact
                  path="/createStockists"
                  index
                  element={
                    <React.Suspense
                      fallback={
                        <Spinner color="#222" style={{ marginTop: 20 }} />
                      }
                    >
                      <CreateStockist userData={userContext} />
                    </React.Suspense>
                  }
                />
                <Route
                  exact
                  path="/createSuperStockist"
                  index
                  element={
                    <React.Suspense
                      fallback={
                        <Spinner color="#222" style={{ marginTop: 20 }} />
                      }
                    >
                      <CreateSuperStockist userData={userContext} />
                    </React.Suspense>
                  }
                />
                <Route
                  exact
                  path="/createSuperCompany"
                  index
                  element={
                    <React.Suspense
                      fallback={
                        <Spinner color="#222" style={{ marginTop: 20 }} />
                      }
                    >
                      <CreateSuperCompany
                        userData={userContext}
                        toast={toast}
                        ToastContainer={ToastContainer}
                      />
                    </React.Suspense>
                  }
                />
                <Route
                  exact
                  path="/createAgents"
                  index
                  element={
                    <React.Suspense
                      fallback={
                        <Spinner color="#222" style={{ marginTop: 20 }} />
                      }
                    >
                      <CreateAgent userData={userContext} />
                    </React.Suspense>
                  }
                />
                <Route
                  exact
                  path="/userDashboard/:username"
                  index
                  element={
                    <React.Suspense
                      fallback={
                        <Spinner color="#222" style={{ marginTop: 20 }} />
                      }
                    >
                      <UserDashboard />
                    </React.Suspense>
                  }
                />
                <Route
                  path="*"
                  element={
                    <React.Suspense
                      fallback={
                        <Spinner color="#222" style={{ marginTop: 20 }} />
                      }
                    >
                      <Dashboard
                        userData={userContext}
                        dataLoading={dataLoading}
                      />
                    </React.Suspense>
                  }
                />
                {level === 1 ? (
                  <Route
                    exact
                    path="/createMessage"
                    index
                    element={
                      <React.Suspense
                        fallback={
                          <Spinner color="#222" style={{ marginTop: 20 }} />
                        }
                      >
                        <CreateMessage
                          currentMessage={message}
                          setNewMessage={setMessage}
                        />
                      </React.Suspense>
                    }
                  />
                ) : null}
              </Routes>
            </div>
          </div>
        </section>
      </section>
    </div>
  );
}

export default Main;
