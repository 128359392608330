import React, { useEffect, useState } from "react";
import api_url from "../constants";
import axios from "axios";

function IsActive() {
  const [activeList, setActiveList] = useState([]);
  useEffect(() => {
    getActiveMasterList();
  }, []);
  const getActiveMasterList = async () => {
    const response = await axios.get(api_url + `/api/getActive`);
    setActiveList(response.data);
  };
  return (
    <div style={{ padding: 10 }}>
      {activeList.map((user) => (
        <div key={user._id}>{user.username}</div>
      ))}
    </div>
  );
}

export default IsActive;
