import React, { useEffect, useState, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import Spinner from "./Spinner";
import { AuthContext } from "../context/AuthContext";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../style/odds.css";
import api_url from "../constants";
import { ReqCallContext } from "../context/ReqCallContext";

function ClientInfo({ userData }) {
  const [authContext, setAuthContext] = useContext(AuthContext);
  const [ulLoading, setulLoading] = useState("");
  const [reqCall, setReqCall] = useContext(ReqCallContext);
  const config = {
    headers: { Authorization: `Bearer ${authContext.token}` },
  };

  const [dataLoading, setDataLoading] = useState(true);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [userList, setUserList] = useState([]);
  const [limit, setLimit] = useState({});
  const [amount, setAmount] = useState("");

  const fetchList = async () => {
    try {
      const response = await axios.get(`${api_url}/list/allClients`, config);
      setUserList(response.data);
      setDataLoading(false);
    } catch (error) {
      console.error("Error fetching list:", error);
      setulLoading("");
    }
  };
  const getUserLimit = async (username) => {
    try {
      setulLoading(username);

      const response = await axios.get(
        `${api_url}/list/getUsedlimitByUsername/${username}`,
        config
      );
      const user = userList.find((user) => user.username === username);
      if (user) {
        user.downBalance = response.data.usedLimit;
        setUserList([
          ...userList.filter((user) => user.username !== username),
          user,
        ]);
      } else {
        console.error("User not found in userList");
      }
      setulLoading("");
    } catch (err) {
      console.error(err);
      setulLoading("");
    }
  };

  const handleSubmit = async () => {
    setSubmitLoading(true);
    try {
      if (limit.username) {
        const response = await axios.post(
          `${api_url}/coin/limitControl`,
          { type: limit.type, username: limit.username, amount },
          config
        );
        await fetchList();
        setLimit({});
        if (response.data && response.data.status) {
          toast.success(response.data.msg);
        } else {
          toast.error(
            response.data.msg ? response.data.msg : "Unknown Error Occurred"
          );
        }
      }
    } catch (error) {
      console.error("Error submitting:", error);
    }

    setAmount("");
    setSubmitLoading(false);
  };

  useEffect(() => {
    setReqCall(reqCall + 1);
    fetchList();
  }, [userData]);

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      {limit.username && (
        <div className="modal-body">
          <div className="modal-content">
            <div className="modal-header">
              Limit {limit.type === 1 ? "Plus" : "Minus"} ({limit.username})
            </div>
            <div className="modal-c" style={{ backgroundColor: "#fff" }}>
              <div>
                <input
                  autoComplete="off"
                  type="text"
                  placeholder="Amount"
                  id="amount"
                  inputMode="numeric"
                  value={amount}
                  onChange={(e) => setAmount(e.target.value)}
                />
              </div>
            </div>
            <div className="modal-btn">
              <button onClick={handleSubmit}>
                {submitLoading ? (
                  <Spinner color={"#fff"} />
                ) : (
                  <span>Submit</span>
                )}
              </button>
              <button className="warn-btn" onClick={() => setLimit({})}>
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
      <div className="header-container">
        <h1>Client Info</h1>
        <div>
          {" "}
          <Link to={"/"} className="tag-link">
            Dashboard
          </Link>{" "}
          / <b>Client Info</b>
        </div>
      </div>

      <div
        className={`box-container ${
          dataLoading ? "fade-in" : "fade-in loaded"
        }`}
      >
        <div
          className={`box table ${dataLoading ? "fade-in" : "fade-in loaded"}`}
          style={{ maxWidth: "100%" }}
        >
          <div
            className="box-header"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <span>Players</span>
          </div>
          <div className="box-body">
            <table style={{ width: "100%" }} className="normal">
              <thead>
                <tr>
                  <th>Username</th>
                  <th>{"Used Limit"}</th>
                  <th>Current Balance</th>
                </tr>
              </thead>
              <tbody>
                {dataLoading ? (
                  <tr colSpan="10">
                    <td colSpan={6}>
                      <Spinner color="#888" />
                    </td>
                  </tr>
                ) : (
                  userList.length > 0 &&
                  userList
                    .sort((a, b) => (a.username > b.username ? 1 : -1))
                    .map((data) => (
                      <tr key={data._id}>
                        <td
                          style={{
                            width: "max-content",
                            whiteSpace: "nowrap",
                          }}
                        >
                          <Link
                            to={`/userDashboard/${data.username}`}
                            style={{ width: "max-content" }}
                          >
                            {data.username} ({data.name.trim()})
                          </Link>
                        </td>

                        <td>
                          <div>
                            {data.downBalance || data.downBalance === 0 ? (
                              <input
                                value={Math.abs(data.downBalance)}
                                readOnly={true}
                                className="input-td"
                              />
                            ) : (
                              <button
                                className="pwd-btn"
                                onClick={() => getUserLimit(data.username)}
                              >
                                {ulLoading === data.username ? (
                                  <Spinner color="#fff" />
                                ) : (
                                  "Get Used Limit"
                                )}
                              </button>
                            )}
                          </div>
                        </td>
                        <td>
                          <div>
                            <input
                              value={data.totalCoins ? data.totalCoins : 0}
                              readOnly={true}
                              className="input-td"
                            />
                          </div>
                        </td>
                      </tr>
                    ))
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}

export default ClientInfo;
