import React, { useState, useEffect, useContext } from "react";
import Spinner from "./Spinner";
import axios from "axios";
import { AuthContext } from "../context/AuthContext";
import { Link } from "react-router-dom";
import "../style/odds.css";
import api_url from "../constants";
import { format } from "date-fns";
import { ReqCallContext } from "../context/ReqCallContext";

function LiveMatches(props) {
  const { username } = props.userData.details;
  const [authContext, setAuthContext] = useContext(AuthContext);
  const [reqCall, setReqCall] = useContext(ReqCallContext);
  const now = new Date(Date.now());
  now.setDate(now.getDate() - 10);
  const config = {
    headers: { Authorization: `Bearer ${authContext.token}` },
  };
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "June",
    "July",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  function formatAMPM(date) {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;
    var strTime = hours + ":" + minutes + " " + ampm;
    return strTime;
  }
  const [matchList, setMatchList] = useState([]);
  const [loading, setLoading] = useState(true);
  const getMatches = async () => {
    if (username !== undefined) {
      const response = await axios.get(
        `${api_url}/sports/getLiveMatches/${username}`,
        config
      );
      setMatchList(response.data);
    }
    setLoading(false);
  };
  useEffect(() => {
    setReqCall(reqCall + 1);
    getMatches();
  }, [username]);

  String.prototype.allReplace = function (obj) {
    var retStr = this;
    for (var x in obj) {
      retStr = retStr.replace(new RegExp(x, "g"), obj[x]);
    }
    return retStr;
  };
  return (
    <div>
      <div className="header-container">
        <h1>Live Matches</h1>
        <div>
          {" "}
          <Link to={"/"} className="tag-link">
            Dashboard
          </Link>{" "}
          / Live Matches
        </div>
      </div>
      <div className="box-container">
        <div className="box table" style={{ maxWidth: "100%" }}>
          <div
            className="box-header"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <span>Live Matches</span>
          </div>
          <div className="box-body">
            <table className="normal" style={{ width: "100%" }}>
              <thead>
                <tr>
                  <th>MID</th>
                  <th>Title</th>
                  <th>Sports</th>
                  <th>Date</th>
                  <th>Profit/Loss</th>
                </tr>
              </thead>
              <tbody>
                {!matchList.length && loading ? (
                  <tr colSpan="10">
                    <td colSpan={6}>
                      <Spinner color="#888" />
                    </td>
                  </tr>
                ) : (
                  matchList
                    .sort((a, b) => {
                      const atimestamp = new Date(a.startTime);
                      const btimestamp = new Date(b.startTime);
                      return btimestamp - atimestamp;
                    })
                    .map((data) => {
                      const matchName = data.matchName;
                      const timestamp = new Date(data.startTime);
                      const day = timestamp.getDate();
                      const month = timestamp.getMonth();
                      const time = timestamp;

                      return (
                        <tr key={data.matchId}>
                          <td>
                            <div></div>
                            <div>{data.matchId}</div>
                          </td>
                          <td>
                            <div className="max-100 bold-td">
                              <Link to={`/matchDetails/${data.matchId}`}>
                                {matchName}
                              </Link>
                            </div>
                          </td>
                          <td>CRICKET</td>
                          <td>
                            <div>
                              {`${day} ${months[month]} ${formatAMPM(
                                new Date(time)
                              )}`}
                            </div>
                          </td>
                          <td>
                            <Link to={"/liveReport/" + data.matchId}>
                              <button className="pwd-btn">Live Report</button>
                            </Link>
                          </td>
                        </tr>
                      );
                    })
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LiveMatches;
