import React, { useEffect, useState, useContext } from "react";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import Spinner from "./Spinner";
import { AuthContext } from "../context/AuthContext";
import "../style/odds.css";
import { ReqCallContext, ReqCallProvider } from "../context/ReqCallContext";
import api_url from "../constants";

function PlayerList(props) {
  const { matchId } = useParams();
  const [authContext, setAuthContext] = useContext(AuthContext);
  const [reqCall, setReqCall] = useContext(ReqCallContext);
  const config = {
    headers: { Authorization: `Bearer ${authContext.token}` },
  };
  const [dataLoading, setDataLoading] = useState(true);
  const [userList, getUserList] = useState([]);
  function removeDuplicates(arr, key) {
    return arr.filter(
      (obj, index, self) => index === self.findIndex((t) => t[key] === obj[key])
    );
  }
  const fetchList = async () => {
    const response = await axios.get(`${api_url}/bet/getPlayerList`, config);
    const { list, users } = response.data;
    const arr = list.concat(users);
    let uniqueArray = removeDuplicates(arr, "username");
    getUserList(uniqueArray);
    setDataLoading(false);
  };
  useEffect(() => {
    setReqCall(reqCall + 1);
    fetchList();
  }, []);
  return (
    <div>
      <div className="header-container">
        <h1>Clients List</h1>
        <div>
          {" "}
          <Link to={"/"} className="tag-link">
            Dashboard
          </Link>{" "}
          / <b>Client List</b>
        </div>
      </div>
      <div className="box-container">
        <div className="box table" style={{ maxWidth: "100%" }}>
          <div
            className="box-header"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <span>All Users</span>
          </div>
          <div className="box-body">
            <table style={{ width: "100%" }} className="normal client-table">
              <thead>
                <tr>
                  <th>User Name</th>
                  <th>Status</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {dataLoading ? (
                  <tr colSpan="10">
                    <td colSpan={6}>
                      <Spinner color="#888" />
                    </td>
                  </tr>
                ) : (
                  userList
                    .sort((a, b) => {
                      if (a.username < b.username) return 1;
                      if (a.username > b.username) return -1;
                      return 0;
                    })
                    .map((data) => (
                      <tr key={data.username}>
                        <td className="uppercase">
                          <Link to={`/userDashboard/${data.username}`}>
                            {data.username} ({data.name.trim()})
                          </Link>
                        </td>

                        <td style={{ color: "green" }}>Active</td>
                        <td>
                          <Link
                            to={
                              "/playerLiveReport/" +
                              matchId +
                              "/" +
                              data.username
                            }
                          >
                            <button className="pwd-btn">Live Report</button>
                          </Link>
                        </td>
                      </tr>
                    ))
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PlayerList;
